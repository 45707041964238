import { useRoutes } from 'react-router-dom';

import { publicRoutes } from './public';
import { Home } from 'features/Home';

export const AppRoutes = () => {

  const commonRoutes = [{ path: '/', element: <Home /> }];

  const element = useRoutes([...publicRoutes, ...commonRoutes]);

  return <>{element}</>;
};
