
import React, { useState, useEffect } from "react";
import { X } from "react-feather";

export const MonthlyDiscountModal = () => {
  const [showModal, setShowModal] = useState();
  const [timeLeft, setTimeLeft] = useState(3 * 24 * 60 * 60);

  // Calculate if today is within the first 3 days of the month
  const isWithinFirstThreeDays = () => {
    const today = new Date();
    const dayOfMonth = today.getDate();
    return dayOfMonth <= 3;
  };

  // Calculate if it's the beginning of the month
  const isBeginningOfMonth = () => {
    const today = new Date();
    return today.getDate() === 1;
  };

  useEffect(() => {
    // Show the modal after 1 minute
    const timer = setTimeout(() => {
      if (isBeginningOfMonth() && isWithinFirstThreeDays()) {
        setShowModal(true);
      }
    }, 3000); // 1 minute

    const countdownInterval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft > 0) {
          return prevTimeLeft - 1;
        }
        return 0; // Ensure timeLeft doesn't go below 0
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
      clearInterval(countdownInterval);
    };

  }, []);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  const claimOffer = () => {
    window.location.href = "https://api.whatsapp.com/send?phone=256782692271&text=Hey Jast Tech, I'm interested in your 50% monthly discount.";
  };

  const closeModal = () => {
    setShowModal(false);
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 text-white flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="mx-4 md:mx-auto border-4 border-gray-800 bg-[#FF460C] py-8 rounded-lg shadow-lg w-full md:w-96">
        <div className="px-8">
          <div className="flex justify-between items-center mb-3">
            <h2 className="text-xl md:text-2xl font-semibold text-center">
              Special New Years Offer!
            </h2>
            <button onClick={closeModal}>
              <X className="text-white h-6 w-6" />
            </button>
          </div>
        </div>

        <div className="text-2xl md:text-5xl font-black text-center mb-4">
          Get <span className="underline">75%</span> off
        </div>
        <div className="text-xl text-center py-4 bg-gray-800">
          <span className="uppercase font-black">for services</span>
          <br />
          <span className="text-sm">Website design, mobile application, software development, graphics design.</span>
        </div>
        <div className="px-8">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 p-4">
            <div className="flex flex-row items-center space-x-2">
              <div className="w-24 h-24 p-2">
                <span className="font-black text-xl md:text-3xl">
                  {formatTime(timeLeft).split(" ")[0]}
                </span>{" "}
                Days
              </div>
              <div className="w-24 h-24 p-2">
                <span className="font-black  text-xl md:text-3xl">
                  {formatTime(timeLeft).split(" ")[1]}
                </span>{" "}
                Hrs
              </div>
              <div className="w-24 h-24 p-2">
                <span className="font-black  text-xl md:text-3xl">
                  {formatTime(timeLeft).split(" ")[2]}
                </span>{" "}
                Mins
              </div>
              <div className="w-24 h-24 p-2">
                <span className="font-black  text-xl md:text-3xl">
                  {formatTime(timeLeft).split(" ")[3]}
                </span>{" "}
                Secs
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between space-x-3 items-center">
          <button
            onClick={claimOffer}
            className="border border-gray-200 w-full md:w-1/2 text-gray-200 px-4 py-2 rounded-md mt-2 md:mt-0"
          >
            Claim
          </button>
          <button
            onClick={closeModal}
            className="bg-[#FF460C] w-full md:w-1/2 text-white px-4 py-2 rounded-md hover:bg-[#FF5722] mt-4 md:mt-0"
          >
            Close
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

