import { useEffect } from "react";
import { BsChevronDoubleUp } from "react-icons/bs";

export function ScrollToTop() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      {/* 👇️ scroll to top on button click */}
      <button
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        style={{
          position: "fixed",
          padding: "1rem 2rem",
          fontSize: "20px",
          bottom: "40px",
          right: "40px",
          backgroundColor: "#fff",

          textAlign: "center",
        }}
        className="border border-gray-800 text-gray-800"
      >
        <BsChevronDoubleUp />
      </button>
    </div>
  );
}
