import { Navigate } from "react-router";
import { lazyImport } from "utils/lazyImport";

const { AboutRoutes } = lazyImport(
  () => import("features/AboutUs"),
  "AboutRoutes"
);
const { ApproachRoutes } = lazyImport(
  () => import("features/Approach"),
  "ApproachRoutes"
);
const { ContactRoutes } = lazyImport(
  () => import("features/Contact"),
  "ContactRoutes"
);
const { ServicesRoutes } = lazyImport(
  () => import("features/Services"),
  "ServicesRoutes"
);
const { WritingsRoutes } = lazyImport(
  () => import("features/Writings"),
  "WritingsRoutes"
);
const { BootcampRoutes } = lazyImport(
  () => import("features/Kesho"),
  "BootcampRoutes"
);

const { QuotationRoutes } = lazyImport(
  () => import("features/Quotation"),
  "QuotationRoutes"
);

const { TermsRoutes } = lazyImport(
  () => import("features/Terms"),
  "TermsRoutes"
);

export const publicRoutes = [
  {
    path: "/about/*",
    element: <AboutRoutes />,
  },
  {
    path: "/terms/*",
    element: <TermsRoutes />,
  },
  {
    path: "/approach/*",
    element: <ApproachRoutes />,
  },
  {
    path: "/contact/*",
    element: <ContactRoutes />,
  },
  {
    path: "/services/*",
    element: <ServicesRoutes />,
  },
  {
    path: "/writings/*",
    element: <WritingsRoutes />,
  },
  {
    path: "/kesho-bootcamp/*",
    element: <BootcampRoutes />,
  },
  {
    path: "/quotation/*",
    element: <QuotationRoutes />,
  },
  { path: "*", element: <Navigate to="." /> },
];
