import React, { useEffect } from "react";
import FAQs from "../components/FAQs";
import { Link } from "react-router-dom";
import pfuImg from "assets/images/clients/pfu.png";
import ncdcImg from "assets/images/clients/ncdc.png";
import ubtebImg from "assets/images/clients/ubteb.png";
import vainvestImg from "assets/images/clients/vainvest.png";
import tickerzoneImg from "assets/images/clients/tickerzone.png";
import digital from "assets/images/Partnership-pana.svg";
import businessGif from "assets/images/Business-risk.gif";
import remote from "assets/images/Dataextraction-pana.svg";
import { BsArrowRight, BsWhatsapp } from "react-icons/bs";
import formImage from "assets/images/bg.png";
import { MonthlyDiscountModal } from "../components/MonthlyDiscountModal";
import { TypeAnimation } from "react-type-animation";
import { Form } from "features/Contact";
import { MainLayout } from "components/Layout";

export const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const ClientLogos = () => {
    const clients = [
      {
        name: "Prison Fellowship Uganda",
        logo: pfuImg,
        website: "https://pfu.ug",
      },
      {
        name: "Tickerzone Inc",
        logo: tickerzoneImg,
        website: "https://tickerzone.com",
      },
      {
        name: "National Curriculum Development Center",
        logo: ncdcImg,
        website: "https://ncdc.go.ug",
      },
      {
        name: "Uganda Business and Technical Examinations Board",
        logo: ubtebImg,
        website: "https://ubteb.go.ug",
      },
      {
        name: "Vainvest Limited",
        logo: vainvestImg,
        website: "https://vainvest-ug.com",
      },
    ];

    return (
      <div className="mt-10 flex flex-wrap justify-start items-center space-x-4">
        {clients.map((client, index) => (
          <a
            key={index}
            href={client.website}
            target="_blank"
            rel="noopener noreferrer"
            className="group flex items-center justify-center rounded-lg p-2 bg-white shadow-md hover:filter hover:brightness-75 mb-4 mx-2"
          >
            <img src={client.logo} alt={client.name} className="h-48 w-48" />
          </a>
        ))}
      </div>
    );
  };
  return (
    <MainLayout>
      <MonthlyDiscountModal />
      <div className="py-4 md:mx-12 mt-16 md:mt-24">
        <div className="mx-4 my-6 md:mb-24 flex flex-col capitalize md:flex-row">
          <div className=" px-2 md:w-8/12 w-full ">
            <div className="mb-5 mt-6 md:h-80 md:mt-20 text-4xl md:text-6xl md:leading-jast font-semibold text-gray-800 tracking-tighter ">
              We provide
              <br />
              <div className="text-[#FF460C]">
                <TypeAnimation
                  sequence={[
                    "Tailored digital innovations",
                    4000,
                    "Personalized software excellence",
                    4000,
                    "Tailor-made IT solutions",
                    4000,
                    "Specialized tech breakthroughs",
                    4000,
                  ]}
                  wrapper="span"
                  speed={10}
                  repeat={Infinity}
                  cursor={false}
                  aria-expanded={false}
                  deletionSpeed={100}
                />
              </div>
              <div> that help your business grow!</div>
            </div>
            <div className="flex flex-col md:flex-row space-y-2 items-start md:items-center md:space-y-0 space-x-0 md:space-x-2">
              <a
                href="https://api.whatsapp.com/send?phone=256770452110&text=Hey Jast Tech, I’d love to hear about your solutions."
                target="_blank"
                rel="noreferrer"
                className="text-white text-center justify-center border-b-4 border-gray-800 bg-[#FF460C] p-4 px-6 flex flex-row items-center space-x-2 w-full md:w-64"
              >
                <BsWhatsapp className="h-4 w-4" />
                <span>Send Us a Message</span>
              </a>
              <Link
                to="kesho-bootcamp"
                className="text-white text-center justify-center border-b-4 border-[#FF460C] bg-gray-800 p-4 px-6 space-x-2 w-full md:w-64"
              >
                Join Kesho Bootcamp
              </Link>
            </div>
          </div>

          <div className="md:w-5/12">
            <div to="/">
              <img
                className="h-full w-full"
                src={businessGif}
                alt="workcationing"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:mb-32">
        <div className="grid md:grid-cols-3 border border-black md:h-80 ">
          <div className="p-12 font-medium text-3xl tracking-jast ">
            Jast Tech Ltd is a private technology company providing
            competitively priced outsourcing services to companies and
            businesses in Uganda.
          </div>
          <div className="bg-gray-600 text-white p-12 tracking-jast font-medium text-3xl">
            Our objective is to become a leading company which facilitates,
            enhances and provides measurable business value through most
            effective uses of Technology and Resources to companies nationwide
          </div>
          <div className="p-12 font-medium text-3xl tracking-jast">
            We establish uncompromisingly high-quality design standards tailored
            to businesses of all sizes, from small enterprises to large-scale
            corporations.
          </div>
        </div>
      </div>
      <div className="md:mx-16">
        <div className="px-2 mt-20 flex flex-col md:flex-row w-full justify-center items-center">
          <div className="md:w-7/12 p-2">
            <p className="text-3xl font-semibold tracking-tight text-[#FF460C] ">
              We Build Effective Platforms
            </p>
            <p className="text-3xl font-semibold tracking-tight text-[#FF460C] ">
              for every level of business
            </p>
            <span className="mt-6 flex ">
              <p className="px-0 font-medium">
                We serve clients at every level of their businesses, in whatever
                capacity we can be most useful, whether as a trusted advisor to
                top management. We strive to build a relationship of trust with
                every client, for the long-term.
              </p>
            </span>
          </div>
          <div className="w-96 md:w-5/12 md:p-2 ">
            <img className="h-full w-full" src={remote} alt="analysis robot" />
          </div>
        </div>
      </div>

      <div className="md:mx-16">
        <div className="px-2 mt-20 flex flex-col md:flex-row w-full justify-center items-center">
          <div className="md:w-full p-2">
            <p className="text-3xl font-semibold tracking-tight text-[#FF460C] ">
              Our Clients
            </p>

            <span className="mt-6 flex ">
              <p className="px-0 font-medium">
                We are proud to serve a diverse range of clients, from startups
                to established enterprises, helping them achieve their
                technological goals.
              </p>
            </span>
          </div>
        </div>
        <ClientLogos />
      </div>

      <div className="md:mx-16">
        <div className="px-2 mt-20 flex flex-col md:flex-row w-full justify-center items-center">
          <div className="md:w-full p-2">
            <p className="text-3xl font-semibold tracking-tight text-[#FF460C] ">
              Our Partners
            </p>
            <span className="mt-6 flex ">
              <p className="px-0 font-medium">
                Our success is greatly attributed to the strong partnerships we
                have built over the years. These partnerships allow us to stay
                at the forefront of technological advancements, ensuring that we
                provide cutting-edge services to businesses of all sizes.
                Together with our partners, we are committed to driving
                innovation, providing top-notch services, and delivering
                measurable value to our clients' businesses.
              </p>
            </span>
            <div className="flex flex-wrap justify-start items-center space-x-4 mt-6">
              <div className="group flex items-center justify-center rounded-lg p-2 bg-white shadow-md hover:filter hover:brightness-75 mb-4 mx-2">
                <a
                  href="https://partnernetwork.ionos.com/partner/jast.tech.limited?origin=PartnerBadge"
                  rel="nofollow"
                >
                  <img
                    src="https://images-2.partnerportal.ionos.com/items/31d52519-b484-4312-9813-e6497b96b2d8/profiles/43aa2e3b-9e62-495d-9925-0ebd199c4688/badges/normal_blue_eco"
                    alt="IONOS - Official Partner"
                    className="h-48 w-48"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:mx-16">
        <div className="px-2 mt-24 flex  flex-col-reverse md:flex-row w-full justify-center items-center">
          <div className="w-96 md:w-5/12 md:p-2 ">
            <img
              className="h-full w-full"
              src={digital}
              alt="digital handshake"
            />
          </div>

          <div className="md:w-7/12 p-2">
            <p className="text-3xl font-semibold tracking-tight text-[#FF460C] ">
              Allow us to be your steadfast Digital Transformation partner,
              committed to supporting you every step of the way - from the very
              first stages of planning, all the way through to successful
              delivery and beyond.
            </p>
            <span className="mt-6 flex ">
              <p className="px-0 font-medium">
                Our dedicated team of expert developers create bespoke software
                products, applications & operating systems for SMEs, enterprise,
                not-for-profit, government and funded start-ups using updated
                technology stacks.
              </p>
            </span>
          </div>
        </div>
      </div>

      <div className="md:mx-16 p-2 mt-12">
        <div className="mt-4 flex flex-col md:flex-row gap-2 mad:gap-0 justify-between text-[#FF460C]">
          <h2 className="text-3xl font-semibold text-[#FF460C] tracking-tight">
            What We Offer
          </h2>
          <Link
            to="/services"
            className="md:text-lg font-semibold tracking-tight md:gap-3 flex items-center cursor-pointer hover:animate-pulse"
          >
            View more services{" "}
            <BsArrowRight color="#FF460C" className="hover:animate-pulse" />
          </Link>
        </div>
        <p className="mt-10 font-medium tracking-tight">
          Our approach to business is simple, We provide quality and timely
          solutions that enable you improve your business. Through active
          research and insight, we strategize with our customers in devising
          cost efficient solutions and deliver them using the best possible
          technologies.
        </p>
      </div>
      <div className="mx-2">
        <div className="md:mx-16 mt-6 flex flex-col md:flex-row border border-slate-900">
          <div className="justify-center flex flex-col  p-12 bg-[#FF460C] md:border-r-2 border-slate-900">
            <h4 className="px-4 text-3xl font-semibold tracking-jast text-slate-200">
              Website Design &
            </h4>
            <h4 className="px-4 text-3xl font-semibold tracking-jast text-slate-200">
              Development
            </h4>
            <p className="mt-10 px-4 mb-8 text-base text-white font-semibold leading-4  ">
              Websites have become one of the most important marketing & sales
              tools and that’s why we take care of all aspects of web design for
              our clients
            </p>
          </div>
          <div className=" p-10">
            <p className="text-3xl text-gray-800 font-medium tracking-jast">
              Mobile Application Development
            </p>
            <span className="mt-10 flex gap-10">
              <p className="px-0 text-gray-800  font-medium">
                Mobile technology gives businesses the opportunity to stay
                connected to customers almost all the time, enterprises are
                moving beyond the desktop world to keep attuned to their
                customer’s needs.
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div
          style={{
            backgroundAttachment: "fixed",
            backgroundImage: `url(${formImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div>
            <Form />
          </div>
        </div>
      </div>
      <div className="mt-20 md:mx-12 mb-5">
        <div className="md:mx-4 mx-2">
          <FAQs />
        </div>
      </div>
    </MainLayout>
  );
};
