import React from "react";
import { useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

export const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    budget: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = () => {
    const { name, email, budget, description } = formData;
    const recipient = "jast.tech.limited@gmail.com"; // Change this to your email address
    const subject = "Inquiry from Jast Tech Website";
    const body = `Name: ${name}\nEmail: ${email}\nBudget: ${budget}\nDescription: ${description}`;

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(); // Send the email when the form is submitted
    // You can also add additional logic here, such as showing a success message
  };

  return (
    <div className="container w-full px-2 md:px-5 py-10 mx-auto">
      <div className="md:w-7/12 mx-auto bg-white shadow-md px-6 md:px-8 py-10 mb-4">
        <div className="my-3 mb-5">
          <h1 className="text-[#FF460C] text-3xl font-semibold tracking-tighter">
            We are Jast Tech Limited
          </h1>
          <p className="text-gray-700 font-semibold px-1">
            We build products that enhance businesses.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              className="block text-gray-700 font-semibold mb-2"
              for="username"
            >
              Name
            </label>
            <input
              className=" font-semibold appearance-none border w-full py-6 px-3 text-gray-700 leading-tight focus:outline-sky-200"
              id="name"
              type="text"
              name="name" // Add the name attribute to identify the field
              placeholder="Mine is Jast Tech, what's yours?"
              value={formData.name} // Connect the input value to the state
              onChange={handleChange} // Call the handleChange function when the input changes
            />
          </div>
          <div className="mb-3">
            <label
              className="block text-gray-700  font-semibold mb-2"
              for="email"
            >
              Email Address
            </label>
            <input
              className=" font-semibold appearance-none border w-full py-6 px-3 text-gray-700 mb-3 leading-tight focus:outline-sky-200  "
              id="email"
              type="email"
              name="email"
              placeholder="Something like jast-tech@gmail.com"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label
              className="block text-gray-700 font-semibold mb-2"
              for="text"
            >
              Budget (if any)
            </label>
            <input
              className=" font-semibold appearance-none border w-full py-6 px-3 text-gray-700 mb-3 leading-tight focus:outline-sky-200  "
              id="budget"
              type="text"
              name="budget"
              placeholder="We can come to an arrangement..."
              value={formData.budget}
              onChange={handleChange}
            />
            <div className="flex items-center">
              <MdKeyboardArrowRight className="text-xl text-gray-700 italic" />
              <span className="text-xs font-semibold text-gray-700 italic">
                Article from Webfx.com that you can refer to for budget
                estimation.
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label
              className="block text-gray-700  font-semibold mb-2"
              for="text"
            >
              Description / Message /feedback
            </label>
            <textarea
              className=" font-semibold appearance-none border  w-full py-6 px-3 text-gray-700 mb-3 leading-tight focus:outline-sky-200  "
              id="description"
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="eg. I want a mobile application that can do A, B C and also D"
            />
          </div>
          <button
            type="submit"
            className="bg-[#FF460C] text-white font-semibold w-full py-4"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
