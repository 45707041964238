import { Navigate, Route, Routes } from "react-router-dom";

import { Contact } from "./Contact";

export const ContactRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Contact />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
