import PropTypes from "prop-types";
import * as React from "react";
import {Navbar, ScrollToTop} from '../Layout/components'
import Footer from '../Layout/components/Footer'


export const MainLayout = ({ children }) => {
  return (
    <div className="h-screen">
      <Navbar/>
      <main className="">{children}</main>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};