import React from "react";
import { faqs } from "../api/data";
import { MdKeyboardArrowRight } from "react-icons/md";

const FAQs = () => {
  return (
    <>
      <div className="px-2 ">
        <h2 className="mb-14 font-semibold text-3xl text-[#FF460C]">
          Frequently Asked Questions
        </h2>{" "}
        <div className="accordion" id="accordionExample">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="accordion-item bg-white border border-gray-200"
            >
              <h2 className="accordion-header mb-0 " id="headingOne">
                <button
                  className=" flex justify-between items-center w-full py-4 px-5 text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none focus:bg-sky-50"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseOne${faq.id}`}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  fill="currentColor"
                >
                  <h1 className="text-md text-gray-800 font-medium ">
                    {faq.qn}
                  </h1>

                  <MdKeyboardArrowRight className={`text-2xl rotate-90`} />
                </button>
              </h2>
              <div
                id={`collapseOne${faq.id}`}
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body py-4 px-5 text-md flex flex-wrap font-normal">
                  {faq.ans}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQs;
