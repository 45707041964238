import React, { useEffect } from "react";
import contact from "assets/images/contact.svg";
import { BsWhatsapp } from "react-icons/bs";
import { Form } from "../components/Form";
import { MainLayout } from "components/Layout";

export const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <MainLayout>
      <div className="mt-20 container px-5 py-24 mx-auto">
        <section className="text-gray-600 body-font">
          <div className="container px-5  mx-auto flex flex-wrap items-center">
            <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
              <h1 className="title-font font-bold text-5xl text-[#ff725e]">
                Reach Out to Us
              </h1>
              <p className="leading-10 font-medium text-3xl text-gray-900 mt-4">
                We'd like to be your guide through your digital transformation
                journey. So let's start a conversation, please fill out the form
                below, and you'll be called within 24 hours!
              </p>
              <div className="mt-6 flex items-center">
                <div className="text-3xl font-bold text-gray-900">
                  For fast inquiries,{" "}
                </div>
                <a
                  href="tel:+256770452110"
                  className="ml-2 inline-flex items-center bg-[#25d366] text-white rounded-full p-3 hover:bg-[#128c7e] transition duration-300"
                >
                  <BsWhatsapp className="mr-2" /> Call or WhatsApp us
                </a>
              </div>
            </div>
            <div className="lg:w-2/6 md:w-1/2 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0">
              <img
                className="w-full h-full"
                src={contact}
                alt="Woman workcationing on the beach"
              />
            </div>
          </div>
        </section>
        <br />
        <br />

        <div />
        <Form />

        <br />
      </div>
    </MainLayout>
  );
};
