import { Spinner } from "components/Elements";
// import { Notifications } from "components/Notifications/Notifications";
// import { queryClient } from "lib/react-query";
import * as React from "react";
// import { ErrorBoundary } from "react-error-boundary";
// import { HelmetProvider } from "react-helmet-async";
// import { QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

// const ErrorFallback = () => {
//   return (
//     <div
//       className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
//       role="alert"
//     >
//       <h2 className="text-lg font-semibold">Sorry, something went wrong </h2>
//       <button
//         onClick={() => {
//           localStorage.clear();
//           window.location.assign(window.location.origin);
//         }}
//         className="mt-4 text-white text-center justify-center border-b-4 border-[#FF460C] bg-gray-800 p-4 px-6 space-x-2 w-full md:w-64"
//       >
//         Refresh
//       </button>
//     </div>
//   );
// };

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <div>Engines rolling...</div>
        </div>
      }
    >
      <>
        <>
          <>
            {/* {process.env.REACT_APP_NODE_ENV !== "test" && (
              <ReactQueryDevtools />
            )} */}
            {/* <Notifications /> */}
            <>
              <Router>{children}</Router>
            </>
          </>
        </>
      </>
    </React.Suspense>
  );
};
