import React, { useEffect, useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import logo from "assets/images/logo-white.svg";
import { Link } from "react-router-dom";
import { File, Phone } from "react-feather";

export const Navbar = ({ page }) => {
  const [open, setOpen] = useState(true);

  const calculateTimeUntilNewYear = () => {
    const now = new Date();
    const newYear = new Date(now.getFullYear() + 1, 0, 1); // Next year's January 1st
    const timeRemaining = newYear - now;
    const secondsRemaining = Math.floor(timeRemaining / 1000);

    const hours = Math.floor(secondsRemaining / 3600);
    const minutes = Math.floor((secondsRemaining % 3600) / 60);
    const seconds = secondsRemaining % 60;

    return {
      hours: String(hours).padStart(2, "0"),
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
    };
  };

  const [countdown, setCountdown] = useState(calculateTimeUntilNewYear());

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown(calculateTimeUntilNewYear());
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(countdownInterval);
  }, []); // Empty dependency array to run the effect only once

  return (
    <div className="fixed w-full top-0 left-0 border-b-4 border-[#FF460C] bg-gray-800">
        <nav className="relative z-20 md:mx-12 py-3 w-full md:w-fit navbar navbar-expand-lg navbar-light">
          <div className="mx-4 md:px-2 md:flex items-center justify-between py-2 w-full ">
            <div className="flex justify-between items-center mr-20">
              <div className="w-4/12">
                <Link to={"/"}>
                  <div>
                    <img src={logo} alt="logo" className="w-/12" />
                  </div>
                </Link>
              </div>
              <div className="flex md:hidden cursor-pointer">
                <MdOutlineMenu
                  className="w-8 h-8 text-white"
                  onClick={() => setOpen(!open)}
                />
              </div>
            </div>

            <div
              className={` md:flex z-[-1] md:z-auto md:static absolute bg-gray-800 w-full left-0 pb-5 md:pb-0 px-8 md:px-0  md:w-8/12 md:justify-end border-b-2 border-[#FF460C] md:border-0 transition-all ease-in duration-500 ${
                open ? "top-[-490px] opacity-100 " : "top-[4.7rem] "
              }`}
            >
              <ul className="md:flex items-center gap-8">
                <li className="nav-item mt-5 md:mt-0 ">
                  <Link
                    to="/"
                    className={page === "home" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium text-sm text-gray-100">
                      Home
                    </p>
                  </Link>
                </li>
                <li className="nav-item mt-5 md:mt-0 ">
                  <Link
                    to="/services"
                    className={page === "services" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium text-sm text-gray-100">
                      Services
                    </p>
                  </Link>
                </li>
                <li className="nav-item mt-5 md:mt-0 ">
                  <Link
                    to="/about"
                    className={page === "about" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium text-sm text-gray-100">
                      About Us
                    </p>
                  </Link>
                </li>
                <li className="nav-item mt-5 md:mt-0 ">
                  <Link
                    to="/contact"
                    className={page === "contact" ? "nav-link " : "nav-link"}
                  >
                    <p className="cursor-pointer font-medium text-sm text-gray-100">
                      Contact
                    </p>
                  </Link>
                </li>
                <li className="nav-item mt-5 md:-mt-10">
                  <a href="tel:+256782692271">
                    <div className="cursor-pointer bg-[#FF460C] w-48 md:w-full p-4 md:py-1 md:h-20 md:px-3 font-medium text-sm text-white">
                      <div className="md:mt-10 flex flex-row items-center space-x-2">
                        <Phone className="h-4 w-4" />
                        <span>Call Now</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="nav-item mt-5 md:-mt-10">
                  <Link to="/quotation">
                    <div className="cursor-pointer w-48 md:w-full p-4 md:py-1 md:h-20 md:px-3 font-medium text-sm text-white border border-[#FF460C]">
                      <div className="md:mt-10 flex flex-row items-center space-x-2">
                        <File className="h-4 w-4" />
                        <span>Request Quotation</span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
  );
};
export default Navbar;
